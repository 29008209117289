<template>
  <div
    class="reinsurance-banner-wrapper"
    data-test-id="reinsurance-banner-wrapper"
    :class="{ 'mkp-dark-mode': mkpDarkMode }"
  >
    <div class="reinsurance-banner" :class="{ 'slider-active': !mkpDarkMode }" data-test-id="reinsurance-banner">
      <transition name="reinsurance-banner-slide-in" mode="out-in">
        <div
          v-show="activeBanner === 0 || mkpDarkMode || !isMd"
          key="reinssurance-banner-0"
          class="reinsurance-banner-item"
          :class="{ active: activeBanner === 0 }"
          data-test-id="reinsurance-banner-trustpilot-not-connected"
        >
          <div data-test-id="reinsurance-banner-customer-not-connected-title" @click="openCustomerCareSidebar">
            <div
              class="icon-rate"
              data-test-id="reinsurance-banner-customer-not-connected"
              :class="mkpDarkMode ? 'u-bg-white' : 'u-bg-black'"
            />
            <span>{{ $t('home.customerReinsurance') }}</span>
            <ClientOnly>
              <!-- TrustBox widget - Drop-Down -->
              <TrustpilotWidget
                :data-template-id="tld === 'FR' ? '5418052cfbfb950d88702476' : '5419b6a8b0d04a076446a9ad'"
                data-style-height="30px"
                :data-theme="mkpDarkMode ? 'dark' : 'light'"
                element-test="trustpilot-widget-not-connected"
                :with-link="true"
              />
              <!-- End TrustBox widget -->
            </ClientOnly>
          </div>
        </div>
      </transition>
      <transition name="reinsurance-banner-slide-in" mode="out-in">
        <div
          v-show="activeBanner === 1 || mkpDarkMode || !isMd"
          key="reinssurance-banner-1"
          class="reinsurance-banner-item"
          :class="{ active: activeBanner === 1 }"
          data-test-id="reinsurance-banner-new-products-not-connected"
        >
          <div @click="openNewProductSidebar">
            <div class="icon-recycle" :class="mkpDarkMode ? 'u-bg-white' : 'u-bg-black'" />
            <span>{{ $t('home.newProductsReinsurance') }}</span>
          </div>
        </div>
      </transition>
      <transition name="reinsurance-banner-slide-in" mode="out-in">
        <div
          v-show="activeBanner === 2 || mkpDarkMode || !isMd"
          key="reinssurance-banner-2"
          class="reinsurance-banner-item"
          :class="{ active: activeBanner === 2 }"
          data-test-id="reinsurance-banner-pro-sellers-not-connected"
        >
          <div @click="openProSellersSidebar">
            <div class="icon-macaron" :class="mkpDarkMode ? 'u-bg-white' : 'u-bg-black'" />
            <span>{{ $t('home.sellersReinsurance') }}</span>
          </div>
        </div>
      </transition>
      <transition name="reinsurance-banner-slide-in" mode="out-in">
        <div
          v-show="activeBanner === 3 || mkpDarkMode || !isMd"
          key="reinssurance-banner-3"
          class="reinsurance-banner-item"
          :class="{ active: activeBanner === 3 }"
          data-test-id="reinsurance-banner-delivery-not-connected"
        >
          <div @click="openDeliverySidebar">
            <div class="icon-delivery" :class="mkpDarkMode ? 'u-bg-white' : 'u-bg-black'" />
            <span>{{ $t('home.deliveryReinsurance') }}</span>
          </div>
        </div>
      </transition>
      <div v-show="isMd && !mkpDarkMode" class="reinsurance-banner-dot-wrapper">
        <div
          v-for="i in 4"
          :key="`reinsurance-banner-dot-${i}`"
          class="reinsurance-banner-dot"
          :class="{ active: activeBanner === i - 1 }"
          @click="activeBanner = i - 1"
        />
      </div>
    </div>
    <RightSidebarSeoModal :active="customerCare.isSidebarOpen">
      <template #content>
        <div
          v-if="customerCare.isSidebarOpen"
          class="right-sidebar-seo-modal-container-header"
          data-test-id="modal-customer-care-header"
        >
          <div class="right-sidebar-seo-modal-container-header-title" data-test-id="modal-customer-care-title">
            <span>{{ $t('home.customerReinsurance') }}</span>
          </div>
        </div>
        <div class="right-sidebar-seo-modal-container-content u-p-md" data-test-id="modal-customer-care-content">
          <div data-test-id="modal-customer-care-content-text" v-html="customerCare.content" />
          <ClientOnly>
            <!-- TrustBox widget - Mini -->
            <TrustpilotWidget
              v-if="showTruspilotWidget && tld === 'FR'"
              class="u-m-y-md"
              data-template-id="53aa8807dec7e10d38f59f32"
              data-style-height="150px"
              data-style-width="auto"
              element-test="trustpilot-widget-customer-care"
              element-test-link="trustpilot-link-customer-care"
              :with-link="true"
            />
            <!-- End TrustBox widget -->
            <a
              :href="country?.trustpilotLink"
              class="u-p-b-sm u-d-f u-f-gap-sm u-ai-fe u-jc-fe u-c-dark-blue"
              data-test-id="trustpilot-link-review-customer-care"
              target="_blank"
              @click="openLink(country?.trustpilotLink)"
            >
              {{ $t('home.readReviewsOnTrustpilot') }}
              <div class="icon-arrow-right" :class="mkpDarkMode ? 'u-bg-white' : 'u-bg-black'" />
            </a>
          </ClientOnly>
        </div>
      </template>
    </RightSidebarSeoModal>
    <RightSidebarSeoModal :active="newProduct.isSidebarOpen">
      <template #content>
        <div class="right-sidebar-seo-modal-container-header" data-test-id="modal-new-product-header">
          <div class="right-sidebar-seo-modal-container-header-title" data-test-id="modal-new-product-title">
            <span>{{ $t('product.newProductLink') }}</span>
          </div>
        </div>
        <div class="right-sidebar-seo-modal-container-content u-p-md" data-test-id="modal-new-product-content">
          <div data-test-id="modal-new-product-content-text" v-html="newProduct.content" />
          <div class="u-d-f u-jc-fe">
            <NuxtLinkLocale
              :to="{ name: 'mission' }"
              class="u-c-dark-blue u-d-f u-ai-fe u-f-gap-sm"
              data-test-id="new-product-link"
            >
              {{ $t('product.newProductLink') }}
              <div class="icon-arrow-right u-bg-dark-blue" />
            </NuxtLinkLocale>
          </div>
        </div>
      </template>
    </RightSidebarSeoModal>
    <RightSidebarSeoModal :active="proSellers.isSidebarOpen">
      <template #content>
        <div class="right-sidebar-seo-modal-container-header" data-test-id="modal-pro-sellers-header">
          <div class="right-sidebar-seo-modal-container-header-title" data-test-id="modal-pro-sellers-title">
            <span>{{ $t('home.sellersReinsurance') }}</span>
          </div>
        </div>
        <div class="right-sidebar-seo-modal-container-content u-p-md" data-test-id="modal-pro-sellers-content">
          <div data-test-id="modal-pro-sellers-content-text-0" v-html="proSellers.content[0]" />
          <div class="u-d-f u-jc-fe">
            <a
              :href="getProUrl()"
              class="u-c-dark-blue u-d-f u-ai-fe u-f-gap-sm"
              data-test-id="pro-sellers-link"
              @click="openLink(getProUrl(), false)"
            >
              {{ $t('home.proBannnerTitle') }}
              <div class="icon-arrow-right u-bg-dark-blue" />
            </a>
          </div>
          <div data-test-id="modal-pro-sellers-content-text-1" v-html="proSellers.content[1]" />
          <div class="u-d-f u-jc-fe">
            <a :href="getProUrl()" class="u-c-dark-blue u-d-f u-ai-fe u-f-gap-sm" @click="openLink(getProUrl(), false)">
              {{ $t('home.proBannnerTitle') }}
              <div class="icon-arrow-right u-bg-dark-blue" />
            </a>
          </div>
        </div>
      </template>
    </RightSidebarSeoModal>
    <RightSidebarSeoModal :active="delivery.isSidebarOpen" class="reinsurance-banner-sidebar">
      <template v-if="delivery.isSidebarOpen" #content>
        <div class="right-sidebar-seo-modal-container-header" data-test-id="modal-delivery-header">
          <div class="right-sidebar-seo-modal-container-header-title" data-test-id="modal-delivery-title">
            <span>{{ $t('home.deliveryReinsurance') }}</span>
          </div>
        </div>
        <div class="right-sidebar-seo-modal-container-content u-p-md" data-test-id="modal-delivery-content">
          <div data-test-id="modal-delivery-content-text" v-html="delivery.content" />
          <div class="u-d-f u-jc-fe">
            <ClientOnly>
              <a
                v-if="contactFormLink"
                :href="contactFormLink"
                class="u-c-dark-blue u-d-f u-ai-fe u-f-gap-sm"
                data-test-id="delivery-contact-link"
                target="_blank"
                @click="openLink(contactFormLink)"
              >
                {{ $t('actions.contactUs') }}
                <div class="icon-arrow-right u-bg-dark-blue" />
              </a>
            </ClientOnly>
          </div>
        </div>
      </template>
    </RightSidebarSeoModal>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useDynamicContentStore } from '@/stores/dynamicContent'
import Mobile from '@/mixins/mobile-mixin'
import RightSidebarSeoModal from '@/components/Modals/SidebarModal/RightSidebarSeoModal'
import TrustpilotWidget from '@/components/TrustpilotWidget'
import URL from '@/mixins/url-mixin'
import i18nMx from '@/mixins/i18n-mixin'

export default {
  name: 'ReinsuranceBanner',
  components: {
    RightSidebarSeoModal,
    TrustpilotWidget,
  },
  mixins: [Mobile, URL, i18nMx],
  props: {
    mkpDarkMode: { type: Boolean, default: false },
  },
  data() {
    return {
      showTruspilotWidget: false,
      activeBanner: 0,
      interval: null,
      customerCare: {
        isSidebarOpen: false,
        content: this.$t('home.customerCareSideBar'),
      },
      newProduct: {
        isSidebarOpen: false,
        content: this.$t('product.newProductBlock'),
      },
      proSellers: {
        isSidebarOpen: false,
        content: [this.$t('home.proSellersSideBar.part1'), this.$t('home.proSellersSideBar.part2')],
      },
      delivery: {
        isSidebarOpen: false,
        content: this.$t('home.deliverySideBar'),
      },
    }
  },
  computed: {
    ...mapState(useDynamicContentStore, {
      co2: store => store.counters.co2,
      products: store => store.counters.products,
    }),
    contactFormLink() {
      const { country, locale } = useCurrentLocale()
      return country?.contactFormLink[locale]
    },
  },
  beforeMount() {
    this.$bus.on('closeRightSidebar', () => {
      // Auto close when no selection
      this.closeRightSidebarModal()
    })
    this.$bus.on('openNewProductSidebar', () => {
      this.openNewProductSidebar()
    })
    this.$bus.on('openCustomerCareSidebar', () => {
      this.openCustomerCareSidebar()
    })
    this.$bus.on('openProSellersSidebar', () => {
      this.openProSellersSidebar()
    })
    this.$bus.on('openDeliverySidebar', () => {
      this.openDeliverySidebar()
    })
  },
  beforeUnmount() {
    this.$bus.off('closeRightSidebar')
    this.$bus.off('openNewProductSidebar')
    this.$bus.off('openCustomerCareSidebar')
    this.$bus.off('openProSellersSidebar')
    this.$bus.off('openDeliverySidebar')
    clearInterval(this.interval)
  },
  mounted() {
    this.showTruspilotWidget = true
    if (this.isMd && !this.mkpDarkMode) {
      this.interval = setInterval(() => this.updateActiveSlide(), 3000)
    }
    const dynamicContentStore = useDynamicContentStore()
    dynamicContentStore.fetchCounters()
  },
  methods: {
    closeRightSidebarModal() {
      this.newProduct.isSidebarOpen = false
      this.customerCare.isSidebarOpen = false
      this.proSellers.isSidebarOpen = false
      this.delivery.isSidebarOpen = false
    },
    openNewProductSidebar() {
      this.newProduct.isSidebarOpen = true
      this.newProduct.content = this.$t('product.newProductBlock', {
        co2Saved: this.co2.toLocaleString(),
        productQuantity: this.products.toLocaleString(),
      })
    },
    openCustomerCareSidebar() {
      this.customerCare.isSidebarOpen = true
      // this.customerCare.content = this.$t('home.customerCareSideBar')
    },
    openProSellersSidebar() {
      this.proSellers.isSidebarOpen = true
      // this.proSellers.content = [this.$t('home.proSellersSideBar.part1'), this.$t('home.proSellersSideBar.part2')]
    },
    openDeliverySidebar() {
      this.delivery.isSidebarOpen = true
      // this.delivery.content = this.$t('home.deliverySideBar')
    },
    openLink(url, blank = true) {
      const link = document.createElement('a')
      link.href = url
      if (blank) {
        link.setAttribute('target', '_blank')
      }
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    updateActiveSlide(i) {
      if (i && i < 3) {
        this.activeBanner = i
      } else if (this.activeBanner < 3) {
        this.activeBanner++
      } else {
        this.activeBanner = 0
      }
    },
  },
}
</script>

<style lang="scss">
.reinsurance-banner-wrapper {
  border-bottom: 1px solid var(--bg-grey);

  @include mq($mq-md) {
    width: calc(100% + $spacing-xl);
    margin-left: -$spacing-md;
    padding: 0 $spacing-md;
  }

  .right-sidebar-seo-modal-container-content a {
    text-decoration: none;
  }

  .right-sidebar-seo-modal-container-content a:hover {
    text-decoration: underline;
  }

  &.mkp-dark-mode {
    background-color: var(--night-blue);
    color: var(--white);
    border-radius: $spacing-xs;
    padding: 0 $spacing-md;

    @include mq($mq-md) {
      width: 100%;
      margin-left: unset;
      .reinsurance-banner {
        gap: $spacing-md;
        flex-direction: column;
        padding: $spacing-md;
        &-item {
          padding-left: 0 !important;
          & > div {
            gap: $spacing-sm;
          }
        }
      }
    }

    @include mq($mq-sm) {
      border-radius: 0;
      .reinsurance-banner {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .reinsurance-banner {
      gap: $spacing-sm;
    }
  }
}
.reinsurance-banner {
  display: flex;
  justify-content: space-between;
  padding-top: $spacing-md;
  padding-bottom: $spacing-md;
  position: relative;

  &-item {
    &:hover {
      text-decoration: underline;
    }
  }
  &.slider-active {
    .reinsurance-banner-item {
      min-height: 35px;
      @include mq($mq-md) {
        width: 100%;
        display: none;
        justify-content: center;
        &.active {
          display: flex;
        }
      }
    }
  }

  &-dot-wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: $spacing-sm;
    width: 100%;
    z-index: 1;
    .reinsurance-banner-dot {
      cursor: pointer;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      background-color: var(--bg-grey);
      margin: 0 5px;
      &.active {
        background-color: var(--dark-blue);
      }
    }
  }

  & > div {
    gap: 0;
    max-width: 100%;
    div {
      align-items: center;
      display: flex;
      font-size: pxToRem(15px);
      max-width: 100%;
      gap: 10px;
      cursor: pointer;
      @include mq($mq-md) {
        font-weight: 800;
        text-decoration: underline;
      }
      @include mq($mq-xs) {
        font-size: pxToRem(12px);
      }
    }
  }
  .trustpilot-widget {
    margin-left: -$spacing-lg;
    margin-top: $spacing-xs;
    @include mq($mq-sm) {
      margin-left: 0;
      width: 130px;
    }
  }
}
.reinsurance-banner-sidebar {
  li {
    list-style: disc;
    margin-left: $spacing-md;
  }
}

.reinsurance-banner-slide-in-enter-active,
.reinsurance-banner-slide-in-leave-active {
  transition: transform 0.3s ease;
}
.reinsurance-banner-slide-in-enter-from {
  transform: translateX(100%);
}
.reinsurance-banner-slide-in-leave-to {
  transform: translateX(-100%);
}
</style>
