<template>
  <div :data-test-id="elementTest">
    <CarouselContainer
      :test-id="elementTest"
      :title="$t('home.reviews')"
      :subtitle="$t('home.reviewsMessage')"
      :link-label="$t('home.reviewsButton')"
      :link-src="'https://fr.trustpilot.com/review/stock-pro.fr'"
      icon="icon-external"
    >
      <template #contentCarouselContainer>
        <TrustpilotWidget
          data-template-id="54ad5defc6454f065c28af8b"
          data-style-height="240px"
          :element-test="elementTest + '-widget'"
        />
      </template>
    </CarouselContainer>
  </div>
</template>

<script>
import CarouselContainer from '@/components/Carousel/CarouselContainer'
import TrustpilotWidget from '@/components/TrustpilotWidget'

export default {
  name: 'TrustpilotCarousel',
  components: {
    CarouselContainer,
    TrustpilotWidget,
  },
  props: {
    elementTest: { type: String, default: 'trustpilot-carousel' },
  },
}
</script>
